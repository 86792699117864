import React from "react";
import { colors } from "../../assets/colors";
import { IPageTypes, ITextTypes } from "../../components/Navbar/menu";
import SectionTitle from "../../components/SectionTitle";
import { formatDate } from "../../utils";
import {
  ExperienceContainer,
  TextTimeline,
  TimelineItem,
  TimelineContainer,
} from "./styles";

const Experience = (): React.ReactElement => {
  return (
    <ExperienceContainer name={IPageTypes.EXPERIENCE}>
      <SectionTitle title={ITextTypes.EXPERIENCE} />
      <TimelineContainer>
        <TimelineItem color={colors.main}>
          <TextTimeline bold="bold">Cartos</TextTimeline>
          <TextTimeline text_size="15">
            Arquiteto de Software | Desenvolvedor Pleno II
          </TextTimeline>
          <TextTimeline text_size="15">
            {formatDate(new Date("2023/12/01"))}
          </TextTimeline>
        </TimelineItem>
        <TimelineItem color={colors.main}>
          <TextTimeline bold="bold" text_color={colors.main}>
            Luby Software
          </TextTimeline>
          <TimelineContainer>
            <TimelineItem color={colors.main}>
              <TextTimeline bold="bold" text_color={colors.main} text_size="18">
                Arquiteto de Software | Desenvolvedor Pleno II
              </TextTimeline>
              <TextTimeline text_color={colors.main} text_size="15">
                {formatDate(new Date("2023/06/01"), new Date("2023/12/01"))}
              </TextTimeline>
            </TimelineItem>
            <TimelineItem color={colors.main}>
              <TextTimeline bold="bold" text_color={colors.main} text_size="18">
                Desenvolvedor Pleno I
              </TextTimeline>
              <TextTimeline text_color={colors.main} text_size="15">
                {formatDate(new Date("2022/06/01"), new Date("2023/06/01"))}
              </TextTimeline>
            </TimelineItem>
            <TimelineItem color={colors.main}>
              <TextTimeline bold="bold" text_color={colors.main} text_size="18">
                Desenvolvedor Júnior III
              </TextTimeline>
              <TextTimeline text_size="15">
                {formatDate(new Date("2021/06/01"), new Date("2022/06/01"))}
              </TextTimeline>
            </TimelineItem>
            <TimelineItem color={colors.main}>
              <TextTimeline bold="bold" text_size="18">
                Desenvolvedor Júnior II
              </TextTimeline>
              <TextTimeline text_size="15">
                {formatDate(new Date("2021/05/01"), new Date("2021/06/01"))}
              </TextTimeline>
            </TimelineItem>
            <TimelineItem color={colors.main}>
              <TextTimeline bold="bold" text_size="18">
                Desenvolvedor Júnior I
              </TextTimeline>
              <TextTimeline text_size="15">
                {formatDate(new Date("2020/09/01"), new Date("2021/05/01"))}
              </TextTimeline>
            </TimelineItem>
          </TimelineContainer>
        </TimelineItem>
        <TimelineItem color={colors.main}>
          <TextTimeline bold="bold">HRocha Softwares</TextTimeline>
          <TextTimeline text_size="15">Mantenedor de Sistemas</TextTimeline>
          <TextTimeline text_size="15">
            {formatDate(new Date("2020/02/01"), new Date("2020/09/01"))}
          </TextTimeline>
        </TimelineItem>
        <TimelineItem color={colors.main}>
          <TextTimeline bold="bold">FAI - Faculdade de Iporá</TextTimeline>
          <TextTimeline text_size="15">Auxiliar de TI</TextTimeline>
          <TextTimeline text_size="15">
            {formatDate(new Date("2018/07/01"), new Date("2020/01/01"))}
          </TextTimeline>
        </TimelineItem>
        <TimelineItem color={colors.main}>
          <TextTimeline bold="bold">
            Instituto Federal de Educação, Ciência e Tecnologia Goiano - Campus
            Iporá
          </TextTimeline>
          <TextTimeline text_size="15">
            Monitor de Lógica de Programação
          </TextTimeline>
          <TextTimeline text_size="15">
            {formatDate(new Date("2017/01/01"), new Date("2018/06/01"))}
          </TextTimeline>
        </TimelineItem>
      </TimelineContainer>
    </ExperienceContainer>
  );
};

export default Experience;
