import { Card, Col } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { Element } from "react-scroll";
import style from "styled-components";
import { colors } from "../../assets/colors";
import { fonts } from "../../assets/fonts";

interface IOptionsText {
  text_size?: number;
  bold?: string;
  text_align?: string;
  margin_top?: number;
  color?: string;
}

export const TecnologiesContainer = style(Element)`
    padding-top: 80px;
    display: flex;
    width: 100%;
    background: none;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: 720px) {
        padding: 210px 20px 0px 20px;
    }
`;

export const ContainerText = style(Col)`
    display: flex;
    width: 100%;
    justify-content: center;
`;

export const TextTecnology = style(Paragraph)<IOptionsText>`
    font-size: ${(props) => props.text_size || 16}px;
    font-family: ${fonts.main_font};
    color: ${(props) => props.color || colors.main};
    font-weight: ${(props) => props.bold || "normal"};
    text-align: ${(props) => props.text_align || "normal"};
    margin-top: ${(props) => props.margin_top || 0}px;
`;

export const CardTecnology = style(Card)`
    display: flex;
    min-height: 450px;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 720px) {
        min-height: 150px;
    }
`;

export const ContainerDescription = style(Col)`
    text-align: center;
    margin-top: 10px;
`;
