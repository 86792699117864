import { Timeline } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { Element } from "react-scroll";
import style from "styled-components";
import { colors } from "../../assets/colors";
import { fonts } from "../../assets/fonts";

interface ITextProps {
  text_size?: string;
  bold?: string;
  text_color?: string;
}

export const ExperienceContainer = style(Element)`
    padding-top: 80px;
    display: flex;
    width: 100%;
    background: none;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: 720px) {
        padding: 210px 20px 0px 20px;
    }
`;

export const TextTimeline = style(Paragraph)<ITextProps>`
    margin-bottom: 4px !important;
    font-size: ${(props) => props.text_size || 20}px;
    font-family: ${fonts.main_font};
    color: ${(props) => props.text_color || colors.main};
    font-weight: ${(props) => props.bold || "normal"};
`;

export const TimelineItem = style(Timeline.Item)`
    color: ${colors.white}
`;

export const TimelineContainer = style(Timeline)`
    max-width: 90%;

    @media only screen and (max-width: 720px) {
        max-width: 90%;
    }
`;
