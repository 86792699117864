const formatDate = (initialDate: Date, lastDate?: Date): string => {
  const initialMonth = convertMonthName(initialDate.getMonth());
  const formatInitialDate = `${initialMonth} de ${initialDate.getFullYear()}`;

  const lastMonth = lastDate ? convertMonthName(lastDate.getMonth()) : null;
  const formatlastDate = lastDate
    ? `${lastMonth} de ${lastDate.getFullYear()}`
    : "até o momento";

  const textReturn: string = `${formatInitialDate} - ${formatlastDate}`;

  return textReturn;
};

const convertMonthName = (month: number): string => {
  const namesMonth: any = {
    0: "Janeiro",
    1: "Fevereiro",
    2: "Março",
    3: "Abril",
    4: "Maio",
    5: "Junho",
    6: "Julho",
    7: "Agosto",
    8: "Setembro",
    9: "Outubro",
    10: "Novembro",
    11: "Dezembro",
  };

  return namesMonth[month];
};

export { formatDate };
