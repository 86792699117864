export interface ITecnologyType {
  key: string;
  avatar: string;
  description: string;
}

export const data: ITecnologyType[] = [
  {
    key: Math.random().toString(36).slice(2, 10).toUpperCase(),
    avatar:
      "https://s3.amazonaws.com/rafaelsgpinheiro.com/images/serverless.png",
    description:
      "Tecnologia que trabalho atualmente, possuo bastante experiência com a utilização deste framework.",
  },
  {
    key: Math.random().toString(36).slice(2, 10).toUpperCase(),
    avatar: "https://s3.amazonaws.com/rafaelsgpinheiro.com/images/aws.png",
    description:
      "Conhecimento em S3, CloudFront, CloudFormation, Lambda, RDS, DynamoDB, EC2, CloudWatch, SQS, SNS, entre outros.",
  },
  {
    key: Math.random().toString(36).slice(2, 10).toUpperCase(),
    avatar: "https://s3.amazonaws.com/rafaelsgpinheiro.com/images/postgres.png",
    description:
      "Utilizo ORMs na maioria das vezes, mas sempre é bom praticar aquele SQL né, por isso utilizo bastante o PGAdmin.",
  },
  {
    key: Math.random().toString(36).slice(2, 10).toUpperCase(),
    avatar: "https://s3.amazonaws.com/rafaelsgpinheiro.com/images/react.png",
    description:
      "Consigo me virar com qualquer aplicação React, conhecendo o básico da implementação e aplicando boas práticas.",
  },
  {
    key: Math.random().toString(36).slice(2, 10).toUpperCase(),
    avatar: "https://s3.amazonaws.com/rafaelsgpinheiro.com/images/antd.png",
    description:
      "Framework muito poderoso para aplicações React, utilizado neste site inclusive.",
  },
  {
    key: Math.random().toString(36).slice(2, 10).toUpperCase(),
    avatar:
      "https://s3.amazonaws.com/rafaelsgpinheiro.com/images/styledcomponents.png",
    description:
      "Fácil utilização e deixa uma estrutura organizacional do código perfeita. Pode ser integrado muito bem com ANTD.",
  },
  {
    key: Math.random().toString(36).slice(2, 10).toUpperCase(),
    avatar: "https://s3.amazonaws.com/rafaelsgpinheiro.com/images/node.png",
    description:
      "Desenvolvo APIs Rest aplicando boas práticas como Clean Code, TDD e Clean Archteture.",
  },
  {
    key: Math.random().toString(36).slice(2, 10).toUpperCase(),
    avatar: "https://s3.amazonaws.com/rafaelsgpinheiro.com/images/mysql.png",
    description:
      "O MySQL é outra opção de base de dados relacional que também tenho bastante conhecimento, porém não utilizo tanto mais.",
  },
  {
    key: Math.random().toString(36).slice(2, 10).toUpperCase(),
    avatar: "https://s3.amazonaws.com/rafaelsgpinheiro.com/images/adonis.png",
    description:
      "Adonis JS é um poderoso framework Node JS, possuo bastante experiência no desenvolvimento de APIs utilizando-o.",
  },
  {
    key: Math.random().toString(36).slice(2, 10).toUpperCase(),
    avatar: "https://s3.amazonaws.com/rafaelsgpinheiro.com/images/docker.png",
    description:
      "Trabalhei bastante com docker nas aplicações com Adonis JS, sei me virar bem com ele.",
  },
  {
    key: Math.random().toString(36).slice(2, 10).toUpperCase(),
    avatar: "https://s3.amazonaws.com/rafaelsgpinheiro.com/images/java.png",
    description:
      "O fundamento principal do meu desenvolvimento veio daqui, mais especificamente com JavaFX.",
  },
  {
    key: Math.random().toString(36).slice(2, 10).toUpperCase(),
    avatar: "https://s3.amazonaws.com/rafaelsgpinheiro.com/images/android.png",
    description:
      "Em relação a dispositivos móveis curto bastante Android, inclusive o meu TCC foi uma aplicação nativa.",
  },
  {
    key: Math.random().toString(36).slice(2, 10).toUpperCase(),
    avatar: "https://s3.amazonaws.com/rafaelsgpinheiro.com/images/firebase.png",
    description:
      "A escolhida como base de dados para o meu TCC, um serviço muito poderoso e fácil de integrar com uma aplicação nativa.",
  },
  {
    key: Math.random().toString(36).slice(2, 10).toUpperCase(),
    avatar: "https://s3.amazonaws.com/rafaelsgpinheiro.com/images/arduino.png",
    description:
      "Automação é apaixonante, com essa tecnologia fui capaz de desenvolver o software de um projeto excepcional.",
  },
];
