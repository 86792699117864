import React from "react";
import { Image, List, Row } from "antd";

import { IPageTypes, ITextTypes } from "../../components/Navbar/menu";
import SectionTitle from "../../components/SectionTitle";
import {
  TecnologiesContainer,
  ContainerText,
  TextTecnology,
  CardTecnology,
  ContainerDescription,
} from "./styles";
import { data } from "./tecnologies";

const Tecnologies = (): React.ReactElement => {
  return (
    <TecnologiesContainer name={IPageTypes.TECNOLOGIES}>
      <SectionTitle title={ITextTypes.TECNOLOGIES} />
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 4,
          xxl: 3,
        }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <CardTecnology>
              <Row>
                <ContainerText>
                  <Image
                    height="90px"
                    width="90px"
                    preview={false}
                    src={item.avatar}
                  />
                </ContainerText>
                <ContainerDescription>
                  <TextTecnology key={item.key}>
                    {item.description}
                  </TextTecnology>
                </ContainerDescription>
              </Row>
            </CardTecnology>
          </List.Item>
        )}
      />
    </TecnologiesContainer>
  );
};

export default Tecnologies;
