import { Layout } from "antd";
import style, { createGlobalStyle } from "styled-components";
import { colors } from "../../assets/colors";

export const BackgroundStyle = createGlobalStyle`
  body { 
    background: ${colors.white};
  }

  .ant-pagination-item-active {
      color: ${colors.main};
      border-color: ${colors.main};

      &:hover{
        color: ${colors.main};
        border-color: ${colors.main};
      }
  }

  .ant-pagination-item {
      &:hover{
        border-color: ${colors.main} ;
      }
  }

  .ant-pagination-item a {
      color: ${colors.main};

      &:hover{
        color: ${colors.main} ;
      }
  }
`;

export const PrincipalContainer = style(Layout)`
    background: none;
    display: flex;
    width: 100vw;
    align-items: center;
    margin-bottom: 50px;
`;

export const InitialContainer = style(Layout)`
    background: none;
    max-width: 55%;

    @media only screen and (max-width: 720px) {
      max-width: 100%;
    }
`;
