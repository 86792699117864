import React from "react";
import { BackTop } from "antd";
import { ButtonToTop, FooterToPage } from "./styles";
import { ArrowUpOutlined } from "@ant-design/icons";

const FooterPage = (): React.ReactElement => {
  return (
    <>
      <BackTop>
        <ButtonToTop size="large" shape="circle" type="primary">
          <ArrowUpOutlined />
        </ButtonToTop>
      </BackTop>
      <FooterToPage>Criado por Rafael Gomes @ 2022</FooterToPage>
    </>
  );
};

export default FooterPage;
