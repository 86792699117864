import React from "react";
import { DividerTitle, SectionDividerTitle } from "./styles";

interface IInputSectionTitle {
  title: string;
}

const SectionTitle = ({ title }: IInputSectionTitle): React.ReactElement => {
  return (
    <>
      <DividerTitle plain>
        <SectionDividerTitle>{title}</SectionDividerTitle>
      </DividerTitle>
    </>
  );
};

export default SectionTitle;
