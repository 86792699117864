import React from "react";
import Navbar from "../../components/Navbar";
import About from "../About";
import Experience from "../Experience";
import FooterPage from "../Footer";
import Tecnologies from "../Tecnologies";
import {
  BackgroundStyle,
  InitialContainer,
  PrincipalContainer,
} from "./styles";

const Initial = (): React.ReactElement => {
  return (
    <div>
      <BackgroundStyle />
      <Navbar />
      <PrincipalContainer>
        <InitialContainer>
          <About />
          <Experience />
          <Tecnologies />
        </InitialContainer>
      </PrincipalContainer>
      <FooterPage />
    </div>
  );
};

export default Initial;
