import { Button } from "antd";
import { Footer } from "antd/lib/layout/layout";
import style from "styled-components";
import { colors } from "../../assets/colors";

export const ButtonToTop = style(Button)`
    background: ${colors.main};
    border-color: ${colors.white};

    &:hover {
      border-color: ${colors.main};
      background: ${colors.white};
      color: ${colors.main};
    }
`;

export const FooterToPage = style(Footer)`
    text-align: center;
    background: ${colors.main};
    color: ${colors.white};
`;
