import { Divider } from "antd";
import Text from "antd/lib/typography/Text";
import style from "styled-components";
import { colors } from "../../assets/colors";
import { fonts } from "../../assets/fonts";

export const DividerTitle = style(Divider)`
    border-top-color: ${colors.main} !important;
`;

export const SectionDividerTitle = style(Text)`
    font-family: ${fonts.main_font};
    font-size: 30px;
    font-weight: bold;

    &.ant-typography {
        color: ${colors.main};
   }
`;
