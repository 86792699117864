import {
  AmazonCircleFilled,
  CodepenCircleFilled,
  GithubOutlined,
  HomeFilled,
  LinkedinOutlined,
} from "@ant-design/icons";
import React from "react";
import { colors } from "../../assets/colors";
import { IPageTypes } from "../../components/Navbar/menu";
import {
  AboutContainer,
  ContainerDescription,
  TextDescription,
  ImageDescription,
  ContainerText,
  TextTitle,
  TextLink,
} from "./styles";

const About = (): React.ReactElement => {
  return (
    <AboutContainer name={IPageTypes.ABOUT}>
      <ContainerDescription>
        <ImageDescription
          preview={false}
          src="https://s3.amazonaws.com/rafaelsgpinheiro.com/images/profile.jpg"
        />
        <ContainerText>
          <TextTitle text_size={35} bold="bold">
            Rafael Silvério Gomes Pinheiro
          </TextTitle>
          <TextDescription text_size={20}>
            <CodepenCircleFilled
              color={colors.main}
              style={{ marginRight: "15px" }}
            />
            Desenvolvedor Full Stack NodeJS
          </TextDescription>
          <TextDescription text_size={20}>
            <AmazonCircleFilled
              color={colors.main}
              style={{ marginRight: "15px" }}
            />
            Desbravando o mundo AWS
          </TextDescription>
          <TextDescription text_size={20}>
            <HomeFilled color={colors.main} style={{ marginRight: "15px" }} />
            Iporá, GO, Brasil
          </TextDescription>
          <TextDescription>
            <TextLink
              text_size={20}
              href="https://www.linkedin.com/in/rafaelsgpinheiro"
              target="_blank"
            >
              <LinkedinOutlined
                color={colors.main}
                style={{ marginRight: "15px" }}
              />
              {"LinkedIn"}
            </TextLink>
            <TextDescription margin_left={10} margin_right={10}>
              |
            </TextDescription>
            <TextLink
              text_size={20}
              href="https://github.com/rafaelgo1705"
              target="_blank"
            >
              <GithubOutlined color={colors.main} /> GitHub
            </TextLink>
          </TextDescription>
        </ContainerText>
      </ContainerDescription>

      <TextDescription text_align="justify" margin_top={50}>
        Tenho como principal característica tentar superar expectativas, pois
        por meio disso consigo incentivar a mim mesmo e também quem está ao meu
        redor. Acredito que críticas construtivas são extremamente necessárias
        para nos auxiliar no crescimento profissional e pessoal. Atualmente
        estou desbravando a infinidade de recursos que a AWS tem a oferecer e a
        melhor maneira de usufruir destes recursos. Se eu tenho medo de errar?
        Claro que não, os erros também fazem parte do aprendizado e ajudam a
        construir uma estrutura sólida de conhecimento. Quanto ao meu objetivo
        de vida, huummm, eu quero chegar o mais longe que conseguir, pois o
        conhecimento não tem limites e como muita gente diz, foguete não dá ré.
        🚀🚀
      </TextDescription>
    </AboutContainer>
  );
};

export default About;
