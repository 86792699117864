import { Image, Layout } from "antd";
import Text from "antd/lib/typography/Text";
import { Element } from "react-scroll";
import style from "styled-components";
import { colors } from "../../assets/colors";
import { fonts } from "../../assets/fonts";

interface IOptionsText {
  text_size?: number;
  bold?: string;
  text_align?: string;
  margin_top?: number;
  margin_left?: number;
  margin_right?: number;
}

export const AboutContainer = style(Element)`
    padding-top: 200px;
    display: flex;
    width: 100%;
    background: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: 720px) {
        padding: 250px 20px 0px 20px;
    }
`;

export const ContainerDescription = style(Layout)`
    display: flex;
    flex-direction: row;
    background: none;
    align-items: center;

    @media only screen and (max-width: 720px) {
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }
`;

export const ImageDescription = style(Image)`
    border-radius: 50%;
    width: 200px;
`;

export const ContainerText = style(Layout)`
    background: none;
    margin-left: 25px;
`;

export const TextDescription = style(Text)<IOptionsText>`
    font-size: ${(props) => props.text_size || 20}px;
    font-family: ${fonts.main_font};
    color: ${colors.main};
    font-weight: ${(props) => props.bold || "normal"};
    text-align: ${(props) => props.text_align || "normal"};
    margin-top: ${(props) => props.margin_top || 0}px;
    margin-left: ${(props) => props.margin_left || 0}px;
    margin-right: ${(props) => props.margin_right || 0}px;
`;

export const TextTitle = style(TextDescription)`
    @media only screen and (max-width: 720px) {
        text-align: center;
    }
`;

export const TextLink = style.a<IOptionsText>`
    font-size: ${(props) => props.text_size || 16}px;
    font-family: ${fonts.main_font};
    margin-left: ${(props) => props.margin_left || 0}px;
    color: ${colors.main} !important;
    font-weight: bold;
    
    &:hover {
        font-weight: normal;
    };
`;
