import React from "react";
import { menu } from "./menu";
import {
  MenuHeader,
  ContainerMenu,
  LogoSite,
  NavbarStyle,
  MenuItem,
} from "./styles";

const Navbar = (): React.ReactElement => {
  return (
    <ContainerMenu>
      <NavbarStyle />
      <a href=".">
        <LogoSite>Rafael Gomes</LogoSite>
      </a>
      <MenuHeader>
        {menu.map((item) => (
          <MenuItem
            key={item.key}
            activeClass={item.activeClass}
            className={item.className}
            to={item.to}
            spy={item.spy}
            smooth={item.smooth}
            duration={item.duration}
          >
            {item.name}
          </MenuItem>
        ))}
      </MenuHeader>
    </ContainerMenu>
  );
};

export default Navbar;
