export enum IPageTypes {
  ABOUT = "about",
  EXPERIENCE = "experience",
  CONTACTS = "contacts",
  TECNOLOGIES = "tecnologies",
}

export enum ITextTypes {
  ABOUT = "Sobre",
  EXPERIENCE = "Experiência",
  CONTACTS = "Contatos",
  TECNOLOGIES = "Tecnologias",
}

interface IMenu {
  key: string;
  activeClass: string;
  className: string;
  to: string;
  spy: boolean;
  smooth: boolean;
  duration: number;
  name: string;
  url: string;
}

const menu: IMenu[] = [
  {
    key: IPageTypes.ABOUT,
    activeClass: "active",
    className: IPageTypes.ABOUT,
    to: IPageTypes.ABOUT,
    spy: true,
    smooth: true,
    duration: 500,
    name: ITextTypes.ABOUT,
    url: `#${IPageTypes.ABOUT}`,
  },
  {
    key: IPageTypes.EXPERIENCE,
    activeClass: "active",
    className: IPageTypes.EXPERIENCE,
    to: IPageTypes.EXPERIENCE,
    spy: true,
    smooth: true,
    duration: 500,
    name: ITextTypes.EXPERIENCE,
    url: `#${IPageTypes.EXPERIENCE}`,
  },
  {
    key: IPageTypes.TECNOLOGIES,
    activeClass: "active",
    className: IPageTypes.TECNOLOGIES,
    to: IPageTypes.TECNOLOGIES,
    spy: true,
    smooth: true,
    duration: 500,
    name: ITextTypes.TECNOLOGIES,
    url: `#${IPageTypes.TECNOLOGIES}`,
  },
];

export { menu };
