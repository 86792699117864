import { Layout } from "antd";
import Text from "antd/lib/typography/Text";
import { Link } from "react-scroll";
import style, { createGlobalStyle } from "styled-components";
import { colors } from "../../assets/colors";

export const NavbarStyle = createGlobalStyle`
  .body {
    margin: 0 !important;
    padding: 0 !important;
  }

  .active {
    color: ${colors.white} !important;
    font-weight: bold;
    border-bottom: 1px solid ${colors.white};
  }

  
`;

export const ContainerMenu = style(Layout)`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  border: none;
  background-color: ${colors.main} !important;
  align-items: center !important;
  background: none;
  height: 80px;
  z-index: 9999;

  @media only screen and (max-width: 720px) {
    flex-direction: column;
    height: auto;
  }
`;

export const LogoSite = style(Text)`
  background: ${colors.main} !important;
  display: flex;
  font-family: "Raleway", sans-serif;
  font-size: 30px;
  color: ${colors.white};
  font-weight: bold;

  @media only screen and (max-width: 720px) {
    font-size: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const MenuHeader = style(Layout)`
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  flex-direction: row;
  justify-content: right;
  background: ${colors.main} !important;
  border: none;
  flex: initial;
  z-index: 9999;

  @media only screen and (max-width: 720px) {
    flex-direction: column;
    background: ${colors.white}
  }
`;

export const MenuItem = style(Link)`
  color: ${colors.white};
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 140px;

  &:hover {
    color: ${colors.white} !important;
    font-weight: bold;
  };

  @media only screen and (max-width: 720px) {
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: normal;

    &:hover {
      color: ${colors.white} !important;
      font-weight: normal;
    };
  }
`;
